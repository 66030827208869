.ltr {
  direction: ltr !important;
}

.rtl {
  direction: rtl !important;
}

.header__btn {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0s;
  padding: 10px 20px;
  display: inline-block;
  margin-right: 10px;
  background-color: #fff;
  border: 1px solid #2c2c2c;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
}

.header__btn:last-child {
  margin-right: 0;
}

.header__btn:hover,
.header__btn.js-active {
  color: #fff;
  background-color: #2c2c2c;
}

.header {
  max-width: 600px;
  margin: 50px auto;
  text-align: center;
}

.header__title {
  margin-bottom: 30px;
  font-size: 2.1rem;
}

.content {
  width: 95%;
  margin: 0 auto 50px;
}

.content__title {
  margin-bottom: 40px;
  font-size: 20px;
  text-align: center;
}

.content__title--m-sm {
  margin-bottom: 10px;
}

.multisteps-form__progress {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
}

.multisteps-form__progress-btn {
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  transition-delay: 0s;
  position: relative;
  padding-top: 20px;
  color: rgba(108, 117, 125, 0.7);
  text-indent: -9999px;
  border: none;
  background-color: transparent;
  outline: none !important;
  cursor: pointer;
}

@media (min-width: 500px) {
  .multisteps-form__progress-btn {
    text-indent: 0;
  }
}

.multisteps-form__progress-btn:before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 13px;
  height: 13px;
  content: "";
  transform: translateX(-50%);
  transition: all 0.15s linear 0s,
    transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
  border: 2px solid currentColor;
  border-radius: 50%;
  background-color: #fff;
  box-sizing: border-box;
  z-index: 3;
}

.multisteps-form__progress-btn:after {
  position: absolute;
  top: 5px;
  left: calc(-50% - 13px / 2);
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  transition-delay: 0s;
  display: block;
  width: 100%;
  height: 2px;
  content: "";
  background-color: currentColor;
  z-index: 1;
}

.multisteps-form__progress-btn:first-child:after {
  display: none;
}

.multisteps-form__progress-btn.js-active {
  color: #007bff;
}

.multisteps-form__progress-btn.js-active:before {
  transform: translateX(-50%) scale(1.2);
  background-color: currentColor;
}

.multisteps-form__form {
  position: relative;
}

.multisteps-form__panel {
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  opacity: 0;
  visibility: hidden;
}

.multisteps-form__panel.js-active {
  height: auto;
  opacity: 1;
  visibility: visible;
}

.multisteps-form__panel[data-animation="scaleOut"] {
  transform: scale(1.1);
}

.multisteps-form__panel[data-animation="scaleOut"].js-active {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0s;
  transform: scale(1);
}

.multisteps-form__panel[data-animation="slideHorz"] {
  left: 50px;
}

.multisteps-form__panel[data-animation="slideHorz"].js-active {
  transition-property: all;
  transition-duration: 0.25s;
  transition-timing-function: cubic-bezier(0.2, 1.13, 0.38, 1.43);
  transition-delay: 0s;
  left: 0;
}

.multisteps-form__panel[data-animation="slideVert"] {
  top: 30px;
}

.multisteps-form__panel[data-animation="slideVert"].js-active {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0s;
  top: 0;
}

.multisteps-form__panel[data-animation="fadeIn"].js-active {
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: linear;
  transition-delay: 0s;
}

.multisteps-form__panel[data-animation="scaleIn"] {
  transform: scale(0.9);
}

.multisteps-form__panel[data-animation="scaleIn"].js-active {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0s;
  transform: scale(1);
}

/* card */

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.wrapper1 {
  width: 100%;
  width: 100%;
  height: auto;
  /* min-height: 100vh; */
  padding: 0px 0px 0px 0px;
  padding-top: 14px;
  display: flex;
  gap: 12px;
}

@media screen and (max-width: 768px) {
  .wrapper {
    height: auto;
    min-height: 100vh;
    padding-top: 100px;
  }
}

.profile-card {
  width: 24%;
  margin: auto;
  /* box-shadow: 0px 8px 60px -10px rgba(13, 28, 39, 0.6); */
  background: #fff;
  border-radius: 12px;
  max-width: 700px;
  position: relative;
  margin-top: 73px;
  min-height: 300px;
}

.profile-card.active .profile-card__cnt {
  filter: blur(6px);
}

.profile-card.active .profile-card-message,
.profile-card.active .profile-card__overlay {
  opacity: 1;
  pointer-events: auto;
  transition-delay: 0.1s;
}

.profile-card.active .profile-card-form {
  transform: none;
  transition-delay: 0.1s;
}

/* .profile-card__img {
  width: 37%;
  padding: 16px;
  height: 40%;
  margin-left: auto;
  margin-right: auto;
  transform: translateY(-50%);
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  z-index: 4;
  background-color: #e0be4fe0;
} */

@media screen and (max-width: 576px) {
  .profile-card__img {
    width: 120px;
    height: 120px;
  }
}

.profile-card__img img {
  display: block;
  /* width: 40%;
  height: 40%; */
  object-fit: fill;
  margin: 0 auto;
}

.profile-card__cnt {
  /* margin-top: -35px; */
  text-align: center;
  padding: 0 0px;
  padding-bottom: 0px;
  transition: all 0.3s;
  min-height: 90px;
}

.profile-card__name {
  font-weight: 700;
  font-size: 14px;
  color: #012690;
  margin-bottom: 15px;
  padding: 0px;
}

.profile-card__txt {
  font-size: 14px;
  font-weight: 500;
  color: #324e63;
  margin-bottom: 15px;
}

.profile-card__txt strong {
  font-weight: 700;
}

.profile-card-loc {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
}

.profile-card-loc__icon {
  display: inline-flex;
  font-size: 27px;
  margin-right: 10px;
}

.profile-card-inf {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 35px;
}

.profile-card-inf__item {
  padding: 10px 35px;
  min-width: 150px;
}

@media screen and (max-width: 768px) {
  .profile-card-inf__item {
    padding: 10px 20px;
    min-width: 120px;
  }
}

.profile-card-inf__title {
  font-weight: 700;
  font-size: 27px;
  color: #324e63;
}

.profile-card-inf__txt {
  font-weight: 500;
  margin-top: 7px;
}

.profile-card-social {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.profile-card-social__item {
  display: inline-flex;
  width: 55px;
  height: 55px;
  margin: 15px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #405de6;
  box-shadow: 0px 7px 30px rgba(43, 98, 169, 0.5);
  position: relative;
  font-size: 21px;
  flex-shrink: 0;
  transition: all 0.3s;
}

@media screen and (max-width: 768px) {
  .profile-card-social__item {
    width: 45px;
    height: 50px;
    margin: 10px;
  }
}

@media screen and (min-width: 768px) {
  .profile-card-social__item:hover {
    transform: scale(1.2);
  }
}

.profile-card-social__item.facebook {
  background: linear-gradient(45deg, #3b5998, #0078d7);
  box-shadow: 0px 4px 30px rgba(43, 98, 169, 0.5);
}

.profile-card-social__item.twitter {
  background: linear-gradient(45deg, #1da1f2, #0e71c8);
  box-shadow: 0px 4px 30px rgba(19, 127, 212, 0.7);
}

.profile-card-social__item.instagram {
  background: linear-gradient(45deg,
      #405de6,
      #5851db,
      #833ab4,
      #c13584,
      #e1306c,
      #fd1d1d);
  box-shadow: 0px 4px 30px rgba(120, 64, 190, 0.6);
}

.profile-card-social__item.behance {
  background: linear-gradient(45deg, #1769ff, #213fca);
  box-shadow: 0px 4px 30px rgba(27, 86, 231, 0.7);
}

.profile-card-social__item.github {
  background: linear-gradient(45deg, #333333, #626b73);
  box-shadow: 0px 4px 30px rgba(63, 65, 67, 0.6);
}

.profile-card-social__item.codepen {
  background: linear-gradient(45deg, #324e63, #414447);
  box-shadow: 0px 4px 30px rgba(55, 75, 90, 0.6);
}

.profile-card-social__item.link {
  background: linear-gradient(45deg, #d5135a, #f05924);
  box-shadow: 0px 4px 30px rgba(223, 45, 70, 0.6);
}

.profile-card-social .icon-font {
  display: inline-flex;
}

.profile-card-ctr {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 72px;
  text-align: center;
  padding-bottom: 0px;
  /* border-top: 1px solid #01269036; */
  padding-top: 13px;
  padding-bottom: 20px;
}

@media screen and (max-width: 576px) {
  .profile-card-ctr {
    flex-wrap: wrap;
  }
}

.profile-card__button {
  background: none;
  border: none;
  font-weight: 700;
  font-size: 16px;
  margin: 0px 0px;
  padding: 5px 0px;
  min-width: 110px;
  border-radius: 50px;
  min-height: 13px;
  color: #fff;
  cursor: pointer;
  backface-visibility: hidden;
  transition: all 0.3s;
}

@media screen and (max-width: 768px) {
  .profile-card__button {
    min-width: 170px;
    margin: 15px 25px;
  }
}

@media screen and (max-width: 576px) {
  .profile-card__button {
    min-width: inherit;
    margin: 0;
    margin-bottom: 16px;
    width: 100%;
    max-width: 300px;
  }

  .profile-card__button:last-child {
    margin-bottom: 0;
  }
}

.profile-card__button:focus {
  outline: none !important;
}

@media screen and (min-width: 768px) {
  .profile-card__button:hover {
    transform: translateY(-5px);
  }
}

.profile-card__button:first-child {
  margin-left: 0;
}

.profile-card__button:last-child {
  margin-right: 0;
}

.profile-card__button.button--blue {
  background: linear-gradient(45deg, #1da1f2, #0e71c8);
  box-shadow: 0px 4px 30px rgba(19, 127, 212, 0.4);
}

.profile-card__button.button--blue:hover {
  box-shadow: 0px 7px 30px rgba(19, 127, 212, 0.75);
}

.profile-card__button.button--orange {
  background: linear-gradient(45deg, #d5135a, #f05924);
  box-shadow: 0px 4px 30px rgba(223, 45, 70, 0.35);
}

.profile-card__button.button--orange:hover {
  box-shadow: 0px 7px 30px rgba(223, 45, 70, 0.75);
}

.profile-card__button.button--gray {
  box-shadow: none;
  background: #dcdcdc;
  color: #142029;
}

.profile-card-message {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 130px;
  padding-bottom: 100px;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}

.profile-card-form {
  box-shadow: 0 4px 30px rgba(15, 22, 56, 0.35);
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  background: #fff;
  border-radius: 10px;
  padding: 35px;
  transform: scale(0.8);
  position: relative;
  z-index: 3;
  transition: all 0.3s;
}

@media screen and (max-width: 768px) {
  .profile-card-form {
    max-width: 90%;
    height: auto;
  }
}

@media screen and (max-width: 576px) {
  .profile-card-form {
    padding: 20px;
  }
}

.profile-card-form__bottom {
  justify-content: space-between;
  display: flex;
}

@media screen and (max-width: 576px) {
  .profile-card-form__bottom {
    flex-wrap: wrap;
  }

  .profile-card {
    width: 100%;
    margin: auto;
    /* box-shadow: 0px 8px 60px -10px rgba(13, 28, 39, 0.6); */
    background: #fff;
    border-radius: 12px;
    max-width: 700px;
    position: relative;
    margin-top: 73px;
    min-height: 300px;
  }
}

.profile-card textarea {
  width: 100%;
  resize: none;
  height: 210px;
  margin-bottom: 20px;
  border: 2px solid #dcdcdc;
  border-radius: 10px;
  padding: 15px 20px;
  color: #324e63;
  font-weight: 500;
  outline: none;
  transition: all 0.3s;
}

.profile-card textarea:focus {
  outline: none;
  border-color: #8a979e;
}

.profile-card__overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  background: rgba(22, 33, 72, 0.35);
  border-radius: 12px;
  transition: all 0.3s;
}

.button-label {
  background: #fff;
  border: 1px solid #ccc;
  font-weight: normal;
  font-size: 15px;
  margin: 0px 0px;
  padding: 5px 0px;
  min-width: 110px;
  border-radius: 50px;
  min-height: 13px;
  color: #ccc;
  cursor: pointer;
  backface-visibility: hidden;
  transition: all 0.3s;
}

.button-label h1 {
  font-size: 1em;
}

.button-label:hover {
  color: #ccc;
}

.button-label:active {
  transform: translateY(2px);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2),
    inset 0px -1px 0 rgba(0, 0, 0, 0.22);
}

@media (max-width: 40em) {
  .button-label {
    padding: 0em 1em 3px;
    margin: 0.25em;
  }
}

#yes-button:checked+.button-label {
  background: #012690;
  color: #efefef;
}

#yes-button:checked+.button-label:hover {
  background: #012690;
  color: #e2e2e2;
}

#no-button:checked+.button-label {
  background: #1179cf;
  color: #efefef;
}

#no-button:checked+.button-label:hover {
  background: #1179cf;
  color: #e2e2e2;
}

#maybe-button:checked+.button-label {
  background: #1179cf;
  color: #efefef;
}

#maybe-button:checked+.button-label:hover {
  background: #2c75d2;
  color: #e2e2e2;
}

.hidden {
  display: none;
}

input[type="radio"] {
  display: none;
}

input[type="radio"]:not(:disabled)~label {
  cursor: pointer;
}

input[type="radio"]:disabled~label {
  color: #bcc2bf;
  border-color: #bcc2bf;
  box-shadow: none;
  cursor: not-allowed;
}

.App1 .btn-outline-primary:focus {
  background: transparent !important;
  color: #012690;
}

.App1 label {
  height: 100%;
  display: block;
  background: white;
  border: 2px solid #ccc;
  border-radius: 21px;
  padding: 9px;
  margin-bottom: 0;
  text-align: center;
  /* box-shadow: 0px 3px 10px -2px rgb(161 170 166 / 50%); */
  position: relative;
}

.App1 input[type="radio"]:checked+label {
  background: #012690;
  color: white;
  box-shadow: 0px 0px 20px #012690;
}

@media only screen and (max-width: 700px) {
  section {
    flex-direction: column;
  }
}

#svg_form_time {
  height: 15px;
  max-width: 80%;
  margin: 40px auto 20px;
  display: block;
}

#svg_form_time circle,
#svg_form_time rect {
  fill: white;
}

.button {
  background: rgb(0 19 72);
  border-radius: 5px;
  padding: 9px 17px;
  display: inline-block;
  margin: 10px;
  font-weight: bold;
  color: white;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgb(0 0 0 / 50%);
}

.buttonPre {
  background: #e0be4f;
  border-radius: 5px;
  padding: 9px 17px;
  display: inline-block;
  margin: 10px;
  font-weight: bold;
  color: white;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgb(0 0 0 / 50%);
}

.disabled {
  display: none;
}

section {
  max-width: 800px;
  background: white;

  backdrop-filter: blur(10px);
  border-radius: 5px;
  transition: transform 0.2s ease-in-out;
  min-height: 345px;
}

input {
  width: 100%;
  margin: 7px 0px;
  display: inline-block;
  padding: 12px 25px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid lightgrey;
  font-size: 1em;
  font-family: inherit;
  background: white;
}

.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  margin: 0;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  padding: 11px 21px;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  text-align: right;
  border-bottom: 1px solid #cccc;
}

.plans {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  max-width: 100%;
  padding: 20px 0px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  border-radius: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.plans .plan input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.rtl .plans .plan {
  cursor: pointer;
  width: 100%;
  padding: 0px 0px 0px 15px;
}

.ltr .plans .plan {
  cursor: pointer;
  width: 100%;
  padding: 0px 15px 0px 0px;
}

.plans .plan .plan-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 22px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid #e1e2e7;
  border-radius: 10px;
  -webkit-transition: -webkit-box-shadow 0.4s;
  transition: -webkit-box-shadow 0.4s;
  -o-transition: box-shadow 0.4s;
  transition: box-shadow 0.4s;
  transition: box-shadow 0.4s, -webkit-box-shadow 0.4s;
  position: relative;
}

.ltr .plans .plan .plan-content img {
  margin-right: 30px;
  height: 58px;
}

.rtl .plans .plan .plan-content img {
  margin-left: 30px;
  height: 40px;
}

.plans .plan .plan-details span {
  margin-bottom: 0px;
  display: block;
  font-size: 16px;
  line-height: 24px;
  color: #252f42;
}

.container .title {
  font-size: 16px;
  font-weight: 500;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  color: #252f42;
  margin-bottom: 20px;
}

.plans .plan .plan-details p {
  color: #646a79;
  font-size: 14px;
  line-height: 18px;
}

.plan-details {
  margin: 10px 0px 0px 0px;
}

.plans .plan .plan-content:hover {
  -webkit-box-shadow: 0px 3px 5px 0px #e8e8e8;
  box-shadow: 0px 3px 5px 0px #e8e8e8;
}

.plans .plan input[type="radio"]:checked+.plan-content:after {
  content: "";
  position: absolute;
  height: 12px;
  width: 12px;
  background: #012690;
  right: 20px;
  top: 20px;
  border-radius: 100%;
  border: 3px solid #fff;
  -webkit-box-shadow: 0px 0px 0px 2px #012690;
  box-shadow: 0px 0px 0px 2px #012690;
}

.plans .one input[type="radio"]:checked+.plan-content:after {
  content: "";
  position: absolute;
  height: 12px;
  width: 12px;
  background: #012690;
  right: 6px;
  top: 15px;
  border-radius: 100%;
  border: 3px solid #fff;
  -webkit-box-shadow: 0px 0px 0px 2px #012690;
  box-shadow: 0px 0px 0px 2px #012690;
}

.plan-details1 {
  padding-top: 6px;
  font-size: 14px;
}

.ltr .plans .plan input[type="radio"]:checked+.plan-content {
  border: 2px solid #012690;
  background: #eaf1fe;
  -webkit-transition: ease-in 0.3s;
  -o-transition: ease-in 0.3s;
  transition: ease-in 0.3s;
  direction: ltr;
}

.rtl .plans .plan input[type="radio"]:checked+.plan-content {
  border: 2px solid #012690;
  background: #eaf1fe;
  -webkit-transition: ease-in 0.3s;
  -o-transition: ease-in 0.3s;
  transition: ease-in 0.3s;
  direction: ltr;
}

@media screen and (max-width: 991px) {
  .plans {
    margin: 0 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 40px;
  }

  .plans .plan {
    width: 100%;
  }

  .plan.complete-plan {
    margin-top: 20px;
  }

  .plans .plan .plan-content .plan-details {
    width: 70%;
    display: inline-block;
  }

  .plans .plan input[type="radio"]:checked+.plan-content:after {
    top: 45%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }
}

@media screen and (max-width: 767px) {
  .plans .plan .plan-content .plan-details {
    width: 60%;
    display: inline-block;
  }
}

@media screen and (max-width: 540px) {
  .plans .plan .plan-content img {
    margin-bottom: 20px;
    height: 56px;
    -webkit-transition: height 0.4s;
    -o-transition: height 0.4s;
    transition: height 0.4s;
  }

  .plans .plan input[type="radio"]:checked+.plan-content:after {
    top: 20px;
    right: 10px;
  }

  .plans .plan .plan-content .plan-details {
    width: 100%;
  }

  .plans .plan .plan-content {
    padding: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }
}

/* inspiration */
.inspiration {
  font-size: 12px;
  margin-top: 50px;
  position: absolute;
  bottom: 10px;
  font-weight: 300;
}

.inspiration a {
  color: #666;
}

@media screen and (max-width: 767px) {

  /* inspiration */
  .inspiration {
    display: none;
  }
}

.changeMobileNo .MuiPaper-root {
  width: 600px !important;
}

.choice-title {
  margin-left: 0;
  margin-bottom: 1.8rem;
}

.ltr .choice-title h4 {
  color: #424242;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: 1rem;
  margin-bottom: 6px;
  margin-left: 7px;
}

.rtl .choice-title h4 {
  color: #424242;
  font-weight: 500;
  letter-spacing: 0px;
  font-size: 1rem;
  margin-bottom: 6px;
  margin-left: 7px;
}

.ltr .choice-title label {
  font-size: 13px;
  color: #001348;
  padding: 4px 1px 4px;
  border-radius: 0px;
  border: 1px solid #001348;
  cursor: pointer;
  display: inline-block;
  letter-spacing: 1px;
  margin: 6px;
  width: 17%;
  text-align: center;
}

.rtl .choice-title label {
  font-size: 13px;
  color: #001348;
  padding: 4px 1px 4px;
  border-radius: 0px;
  border: 1px solid #001348;
  cursor: pointer;
  display: inline-block;
  letter-spacing: 1px;
  margin: 6px;
  width: 17%;
  text-align: center;
  direction: initial;
}

.choice-title input[type="radio"] {
  display: none;
  transition: all 200ms ease-out;
}

.choice-title input[type="radio"]:hover+label {
  opacity: 0.8;
  border: 1px solid #001348;
  color: #78909c;
}

.choice-title input[type="radio"]:checked+label {
  background-color: #001348;
  color: white;
}

.receipt {
  background-color: #fff;
  width: 100%;
  position: relative;
}

.receipt:after {
  background-image: linear-gradient(135deg, #fff 0.5rem, transparent 0),
    linear-gradient(-135deg, #fff 0.5rem, transparent 0);
  background-position: left-bottom;
  background-repeat: repeat-x;
  background-size: 1rem;
  content: "";
  display: block;
  position: absolute;
  bottom: -1rem;
  left: 0;
  width: 100%;
  height: 1rem;
}

.receipt__header {
  text-align: center;
}

.receipt__title {
  color: #d9b327;
  font-size: 1.6rem;
  font-weight: 700;
  margin: 1rem 0 0.5rem;
}

.receipt__date {
  font-size: 0.8rem;
  color: #666;
  margin: 0.5rem 0 1rem;
}

.receipt__list {
  margin: 2rem 0 1rem;
  padding: 0;
}

.receipt__list-row {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  position: relative;
}

.receipt__list-row:after {
  content: "";
  display: block;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 100%;
  position: absolute;
  top: -0.25rem;
  z-index: 1;
}

.receipt__item {
  background-color: #fff;
  z-index: 2;
  padding: 0 0.15rem 0 0;
  color: #1f1f1f;
}

.ltr .receipt__cost {
  margin: 0;
  padding: 0 0 0 0.15rem;
  background-color: #fff;
  z-index: 2;
  color: #1f1f1f;
}

.rtl .receipt__cost {
  margin: 0;
  padding: 0 0 0 0.15rem;
  background-color: #fff;
  z-index: 2;
  color: #1f1f1f;
  direction: initial;
}

.receipt__list-row--total {
  border-top: 1px solid #d9b327;
  padding: 1.5rem 0 0;
  margin: 1.5rem 0 0;
  font-weight: 700;
}

/* === HEADING STYLE #3 === */
.ltr .three h1 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5em;
  padding-bottom: 6px;
  position: relative;
  padding-top: 10px;
}

.ltr .three h1:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 55px;
  background-color: #012690;
}

.ltr .three h1:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  height: 1px;
  width: 30%;
  max-width: 255px;
  background-color: #012690;
}

.rtl .three h1 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5em;
  padding-bottom: 6px;
  position: relative;
  padding-top: 10px;
  font-family: "Cairo", sans-serif !important;
}

.rtl .three h1:before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  height: 5px;
  width: 55px;
  background-color: #012690;
}

.rtl .three h1:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 2px;
  height: 1px;
  width: 30%;
  max-width: 255px;
  background-color: #012690;
}

.notavailable {
  height: 22px;
  width: 22px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.available {
  height: 22px;
  width: 22px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #001348;
  display: inline-block;
}

.reserved {
  height: 22px;
  width: 22px;
  background-color: #000;
  border-radius: 50%;
  display: inline-block;
}

.ltr .fixedTop {
  position: fixed;
  z-index: 999;

  left: 0;
  overflow-x: hidden;
  padding-top: 20px;
}

.rtl .fixedTop {
  position: fixed;
  z-index: 999;
  right: 5px;
  overflow-x: hidden;
  padding-top: 20px;
}

.ltr nav .sideFixed {
  height: 100%;
  position: fixed;
  z-index: 999;
  left: 0;
  padding-top: 20px;
  width: 260px;
}

.rtl nav .sideFixed {
  height: 100%;
  position: fixed;
  z-index: 999;
  right: 0;
  padding-top: 20px;
}

.rtl nav ul {
  padding-right: 2rem !important;
}

.rtl div .text-sm-left {
  text-align: right !important;
  float: left;
}

.reserved1 {
  background-color: #000;
  color: #fff !important;
  pointer-events: none;
  border: 1px solid #000 !important;
}

.notavailable1 {
  background-color: #ccc;
  color: #001348d1 !important;
  pointer-events: none;
  border: 1px solid #ccc !important;
}

.modelFooter,
.MuiDialogActions-root {

  border-top: 1px solid #ccc !important;
}

.timetable {
  display: grid;
  grid-template-areas: ". week" "time content";
  grid-template-rows: 60px;
  width: 100%;
  /* height: 100vh; */
  grid-template-columns: 0px;
  /* border: 1px solid #eceff1; */
  max-height: 0px;
}

.timetable .accent-pink-gradient {
  display: flex;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #ee8ebc, #eea08e);
  transition: 0.2s ease box-shadow, 0.2s ease transform;
}

.timetable .accent-pink-gradient:hover {
  box-shadow: 0 20px 30px 0 rgba(238, 142, 188, 0.3);
  transform: scale(1.05);
}

.timetable .accent-orange-gradient {
  display: flex;
  width: 100%;
  /* height: 100%; */
  /* background: linear-gradient(135deg, #012690, #012690); */
  transition: 0.2s ease box-shadow, 0.2s ease transform;
  justify-content: center;
  color: #012690;
  font-size: 13px;
  margin-bottom: 6px;
  border: 1px solid;
  padding: 7px;
  margin: 5px 3px 1px 0px;
}

/* .timetable .accent-orange-gradient:hover {
  box-shadow: 0 20px 30px 0 rgba(238, 192, 142, 0.3);
  transform: scale(1.05);
} */
.timetable .accent-green-gradient {
  display: flex;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #bcee8e, #8eeea0);
  transition: 0.2s ease box-shadow, 0.2s ease transform;
}

.timetable .accent-green-gradient:hover {
  box-shadow: 0 20px 30px 0 rgba(188, 238, 142, 0.3);
  transform: scale(1.05);
}

.timetable .accent-cyan-gradient {
  display: flex;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #8eeec0, #8edcee);
  transition: 0.2s ease box-shadow, 0.2s ease transform;
}

.timetable .accent-cyan-gradient:hover {
  box-shadow: 0 20px 30px 0 rgba(142, 238, 192, 0.3);
  transform: scale(1.05);
}

.timetable .accent-blue-gradient {
  display: flex;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #8ebcee, #a08eee);
  transition: 0.2s ease box-shadow, 0.2s ease transform;
}

.timetable .accent-blue-gradient:hover {
  box-shadow: 0 20px 30px 0 rgba(142, 188, 238, 0.3);
  transform: scale(1.05);
}

.timetable .accent-purple-gradient {
  display: flex;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #c08eee, #ee8edc);
  transition: 0.2s ease box-shadow, 0.2s ease transform;
}

.timetable .accent-purple-gradient:hover {
  box-shadow: 0 20px 30px 0 rgba(192, 142, 238, 0.3);
  transform: scale(1.05);
}

.timetable .weekend {
  background: #fbfbfc;
  color: #87a1ad;
}

.timetable .week-names {
  grid-area: week;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-transform: uppercase;
  font-size: 12px;
}

.timetable .week-names>div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  /* box-shadow: inset 1px 0 0 #eceff1; */
  background-color: #012690;
  color: #fff;
}

.timetable .time-interval {
  grid-area: time;
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  font-size: 14px;
}

.timetable .time-interval>div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 1px 0 0 #eceff1;
}

.timetable .content1 {
  grid-area: content;
  display: grid;
  grid-template-rows: repeat(10, 1fr);
  grid-template-columns: repeat(7, 1fr);
  max-height: inherit;
}

.timetable .content1>div {
  /* box-shadow: inset 1px 0 0 #eceff1, inset 0 1px 0 0 #eceff1; */
  /* border: 1px solid #eceff1; */
  text-align: center;
  max-height: 73px;
  padding: 3px;
}

.clearfloat {
  content: "";
  display: block;
  clear: both;
}

.cards {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.cards li {
  display: inline-block;
  margin: 4px;
  padding: 6px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  font-size: 0.9em;
  transition: all 0.2s ease-in-out;
  text-align: center;
}

.cards li:after,
.cards li:before {
  content: "";
  display: block;
  clear: both;
}

.cards li img {
  display: inline-block;
  width: 100%;
  height: auto;
  max-width: 103px;
  float: left;
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease-in-out;
}

.cards li .details {
  float: left;
  text-align: left;
  transition: all 0.5s ease-in-out;
  text-shadow: 1px 1px rgba(0, 0, 0, 0);
  min-width: 250px;
}

.cards li .details>span,
.cards li .details>a {
  display: block;
  padding: 0 0px 0 8px;
  margin-bottom: 0px;
  text-decoration: none;
  position: relative;
}

.cards li .details>span:before,
.cards li .details>a:before {
  display: inline-block;
  font: normal normal normal 13px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 15px;
  width: 15px;
  text-align: center;
  color: #444;
}

.cards li .details a {
  color: #57893c;
  transition: color 0.5s ease-in-out;
}

.cards li .details a:hover,
.cards li .details a:focus {
  color: #97c77e;
  transition: color 0.5s ease-in-out;
}

.cards li .details .name {
  font-weight: 600;
}

.cards li:hover img {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  transition: all 0.4s ease-in-out;
}

.cards li:hover .details {
  transition: all 0.5s ease-in-out;
  text-shadow: 1px 1px #dfe295;
}

@media (max-width: 550px) {
  .cards li {
    display: block;
    width: auto;
    max-width: 200px;
    margin: 2px 2px;
    padding: 6px;
    border-radius: 0;
  }

  .cards li img,
  .cards li .details {
    float: none;
  }

  .cards li img {
    margin: 0 auto;
  }

  .cards li .details {
    margin-top: 20px;
    text-align: center;
    min-width: 0;
  }

  .cards li .details span,
  .cards li .details a {
    padding: 0;
    white-space: break-all;
    word-wrap: break-word;
  }

  .cards li .details span:before,
  .cards li .details a:before {
    display: none;
  }

  .ltr nav .sideFixed {
    height: 100%;
    position: relative;
    z-index: 9999;
    left: 0;
    padding-top: 20px;
    width: 260px;
  }

  .rtl nav .sideFixed {
    height: 100%;
    position: relative;
    z-index: 9999;
    right: 0;
    padding-top: 20px;
  }
}

.sportsValue {
  display: flex;
  justify-content: space-between;
}

.ltr .basic {
  width: 120px;
  background: #012690;
  color: white;
  padding: 5px;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
  font-size: 16px;
  text-align: center;
  position: relative;
  left: -4px;
  top: 56px;
}

.rtl .basic {
  width: 120px;
  background: #012690;
  color: white;
  padding: 3px;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
  font-size: 15px;
  text-align: center;
  position: relative;
  right: -4px;
  top: 56px;
}

.select-box {
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto;
  font-size: 18px;
  color: #60666d;
}

@media (min-width: 768px) {
  .select-box {
    width: 70%;
  }
}

@media (min-width: 992px) {
  .select-box {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .select-box {
    width: 100%;
  }
}

.select-box__current {
  position: relative;
  /* box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1); */
  cursor: pointer;
  outline: none;
}

.select-box__current:focus+.select-box__list {
  opacity: 1;
  -webkit-animation-name: none;
  animation-name: none;
}

.select-box__current:focus+.select-box__list .select-box__option {
  cursor: pointer;
}

.select-box__current:focus .select-box__icon {
  transform: translateY(-50%) rotate(180deg);
}

.select-box__icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 20px;
  opacity: 0.3;
  transition: 0.2s ease;
}

.select-box__value {
  display: flex;
}

.select-box__input {
  display: none;
}

.select-box__input:checked+.select-box__input-text {
  display: block;
}

.select-box__input-text {
  display: none;
  width: 100%;
  margin: 0;
  padding: 15px;
  background-color: #fff;
}

.select-box__list {
  position: absolute;
  z-index: 999999;
  width: 100%;
  padding: 0;
  list-style: none;
  opacity: 0;
  -webkit-animation-name: HideList;
  animation-name: HideList;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: step-start;
  animation-timing-function: step-start;
  box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
}

.select-box__option {
  display: block;
  padding: 15px;
  background-color: #fff;
  margin-bottom: 0px;
}

.select-box__option:hover,
.select-box__option:focus {
  color: #546c84;
  background-color: #fbfbfb;
}

@-webkit-keyframes HideList {
  from {
    transform: scaleY(1);
  }

  to {
    transform: scaleY(0);
  }
}

@keyframes HideList {
  from {
    transform: scaleY(1);
  }

  to {
    transform: scaleY(0);
  }
}

/* profile page start */

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ul ul {
  margin-bottom: 0;
}

dfn {
  font-style: italic;
}

strong {
  font-weight: bolder;
}

a {
  text-decoration: none;
  color: #5e72e4;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  text-decoration: none;
  color: #233dd2;
}

a:not([href]):not([tabindex]) {
  text-decoration: none;
  color: inherit;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  text-decoration: none;
  color: inherit;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

code {
  font-size: 1em;
}

img {
  vertical-align: middle;
  border-style: none;
}

caption {
  padding-top: 1rem;
  padding-bottom: 1rem;
  caption-side: bottom;
  text-align: left;
  color: #8898aa;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

legend {
  font-size: 1.5rem;
  line-height: inherit;
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5rem;
  padding: 0;
  white-space: normal;
  color: inherit;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

[hidden] {
  display: none !important;
}

.display-2 {
  font-size: 2.75rem;
  font-weight: 600;
  line-height: 1.5;
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

code {
  font-size: 87.5%;
  word-break: break-word;
  color: #f3a4b5;
}

a>code {
  color: inherit;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}



@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  margin-right: -15px;
  margin-left: -15px;
  flex-wrap: wrap;
}

.col-4,
.col-8,
.col,
.col-md-10,
.col-md-12,
.col-lg-3,
.col-lg-4,
.col-lg-6,
.col-lg-7,
.col-xl-4,
.col-xl-6,
.col-xl-8 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  max-width: 100%;
  flex-basis: 0;
  flex-grow: 1;
}

.col-4 {
  max-width: 33.33333%;
  flex: 0 0 33.33333%;
}

.col-8 {
  max-width: 66.66667%;
  flex: 0 0 66.66667%;
}

@media (min-width: 768px) {
  .col-md-10 {
    max-width: 83.33333%;
    flex: 0 0 83.33333%;
  }

  .col-md-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

@media (min-width: 992px) {
  .col-lg-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-lg-4 {
    max-width: 33.33333%;
    flex: 0 0 33.33333%;
  }

  .col-lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-lg-7 {
    max-width: 58.33333%;
    flex: 0 0 58.33333%;
  }

  .order-lg-2 {
    order: 2;
  }
}

@media (min-width: 1200px) {
  .col-xl-4 {
    max-width: 33.33333%;
    flex: 0 0 33.33333%;
  }

  .col-xl-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-xl-8 {
    max-width: 66.66667%;
    flex: 0 0 66.66667%;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }
}

.form-control {
  font-size: 1rem;
  line-height: 1.5;
  display: block;
  width: 100%;
  height: calc(2.75rem + 2px);
  padding: 0.625rem 0.75rem;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  color: #8898aa;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  border: 0;
  background-color: transparent;
}

.form-control:focus {
  color: #8898aa;
  border-color: rgba(50, 151, 211, 0.25);
  outline: 0;
  background-color: #fff;
  box-shadow: none, none;
}

.form-control:-ms-input-placeholder {
  opacity: 1;
  color: #adb5bd;
}

.form-control::-ms-input-placeholder {
  opacity: 1;
  color: #adb5bd;
}

.form-control::placeholder {
  opacity: 1;
  color: #adb5bd;
}

.form-control:disabled,
.form-control[readonly] {
  opacity: 1;
  background-color: #e9ecef;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    margin-bottom: 0;
    align-items: center;
    justify-content: center;
  }

  .form-inline .form-group {
    display: flex;
    margin-bottom: 0;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .input-group {
    width: auto;
  }
}

.btn {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  display: inline-block;
  padding: 0.625rem 1.25rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  /* border: 1px solid transparent; */
  border-radius: 0.375rem;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover,
.btn:focus {
  text-decoration: none;
}

.btn:focus {
  outline: 0;
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.btn:disabled {
  opacity: 0.65;
  box-shadow: none;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn:not(:disabled):not(.disabled):active {
  box-shadow: none;
}

.btn:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08),
    none;
}

.btn-primary {
  color: #fff;
  border-color: #012690;
  background-color: #012690;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-primary:hover {
  color: #fff;
  border-color: #5e72e4;
  background-color: #5e72e4;
}

.btn-primary:focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08),
    0 0 0 0 rgba(94, 114, 228, 0.5);
}

.btn-primary:disabled {
  color: #fff;
  border-color: #5e72e4;
  background-color: #5e72e4;
}

.btn-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  border-color: #5e72e4;
  background-color: #324cdd;
}

.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none, 0 0 0 0 rgba(94, 114, 228, 0.5);
}

.btn-info {
  color: #fff;
  border-color: #11cdef;
  background-color: #11cdef;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-info:hover {
  color: #fff;
  border-color: #11cdef;
  background-color: #11cdef;
}

.btn-info:focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08),
    0 0 0 0 rgba(17, 205, 239, 0.5);
}

.btn-info:disabled {
  color: #fff;
  border-color: #11cdef;
  background-color: #11cdef;
}

.btn-info:not(:disabled):not(.disabled):active {
  color: #fff;
  border-color: #11cdef;
  background-color: #0da5c0;
}

.btn-info:not(:disabled):not(.disabled):active:focus {
  box-shadow: none, 0 0 0 0 rgba(17, 205, 239, 0.5);
}

.btn-default {
  color: #fff;
  border-color: #172b4d;
  background-color: #172b4d;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-default:hover {
  color: #fff;
  border-color: #172b4d;
  background-color: #172b4d;
}

.btn-default:focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08),
    0 0 0 0 rgba(23, 43, 77, 0.5);
}

.btn-default:disabled {
  color: #fff;
  border-color: #172b4d;
  background-color: #172b4d;
}

.btn-default:not(:disabled):not(.disabled):active {
  color: #fff;
  border-color: #172b4d;
  background-color: #0b1526;
}

.btn-default:not(:disabled):not(.disabled):active:focus {
  box-shadow: none, 0 0 0 0 rgba(23, 43, 77, 0.5);
}

.btn-sm {
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 0.25rem 0.5rem;
  border-radius: 0.375rem;
}

.dropdown {
  position: relative;
}

.dropdown-menu {
  font-size: 1rem;
  position: absolute;
  z-index: 1000;
  top: 100%;
  left: 0;
  display: none;
  float: left;
  min-width: 10rem;
  margin: 0.125rem 0 0;
  padding: 0.5rem 0;
  list-style: none;
  text-align: left;
  color: #525f7f;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.4375rem;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1),
    0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
}

.dropdown-menu.show {
  display: block;
  opacity: 1;
}

.ltr .dropdown-menu-right {
  right: 5px;
  left: auto;
}

.rtl .dropdown-menu-right {
  right: 0;
  right: auto;
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  overflow: hidden;
  height: 0;
  margin: 0.5rem 0;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  font-weight: 400;
  display: block;
  clear: both;
  width: 100%;
  padding: 0.25rem 1.5rem;
  text-align: inherit;
  white-space: nowrap;
  color: #212529;
  border: 0;
  background-color: transparent;
}

.dropdown-item:hover,
.dropdown-item:focus {
  text-decoration: none;
  color: #16181b;
  background-color: #f6f9fc;
}

.dropdown-item:active {
  text-decoration: none;
  color: #fff;
  background-color: #5e72e4;
}

.dropdown-item:disabled {
  color: #8898aa;
  background-color: transparent;
}

.dropdown-header {
  font-size: 0.875rem;
  display: block;
  margin-bottom: 0;
  padding: 0.5rem 1.5rem;
  white-space: nowrap;
  color: #8898aa;
}

.input-group {
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
}

.input-group>.form-control {
  position: relative;
  width: 1%;
  margin-bottom: 0;
  flex: 1 1 auto;
}

.input-group>.form-control+.form-control {
  margin-left: -1px;
}

.input-group>.form-control:focus {
  z-index: 3;
}

.input-group>.form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group>.form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend {
  display: flex;
}

.input-group-prepend .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.input-group-text,
.input-group-prepend .input-group-text+.btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-text {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
  margin-bottom: 0;
  padding: 0.625rem 0.75rem;
  text-align: center;
  white-space: nowrap;
  color: #adb5bd;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  background-color: #fff;
  align-items: center;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

@media (max-width: 767.98px) {

  .navbar-expand-md>.container,
  .navbar-expand-md>.container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

/* .card>hr {
  margin-right: 0;
  margin-left: 0;
}

.card-body {
  padding: 1.5rem;
  flex: 1 1 auto;
} */

/* .card-header {
  margin-bottom: 0;
  padding: 1.25rem 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  background-color: #fff;
} */

.card-header:first-child {
  border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  font-size: 0.75rem;
  display: flex;
  overflow: hidden;
  height: 1rem;
  border-radius: 0.375rem;
  background-color: #e9ecef;
  box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1 1;
}

.bg-secondary {
  background-color: #f7fafc !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #d2e3ee !important;
}

.bg-default {
  background-color: #172b4d !important;
}

a.bg-default:hover,
a.bg-default:focus,
button.bg-default:hover,
button.bg-default:focus {
  background-color: #0b1526 !important;
}

.bg-white {
  background-color: #fff !important;
}

a.bg-white:hover,
a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-white {
  background-color: #fff !important;
}

.border-0 {
  border: 0 !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.d-none {
  display: none !important;
}

.d-flex {
  display: flex !important;
}

@media (min-width: 768px) {
  .d-md-flex {
    display: flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.align-items-center {
  align-items: center !important;
}

@media (min-width: 1200px) {
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
}

.float-right {
  float: right !important;
}

.shadow,
.card-profile-image img {
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
}

@media (min-width: 992px) {
  .pl-lg-4 {
    padding-left: 1.5rem !important;
  }

  .pt-lg-8 {
    padding-top: 8rem !important;
  }

  .ml-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-bold {
  font-weight: 600 !important;
}

.text-white {
  color: #fff !important;
}

.text-white {
  color: #fff !important;
}

a.text-white:hover,
a.text-white:focus {
  color: #e6e6e6 !important;
}

.text-muted {
  color: #8898aa !important;
}

@media print {

  *,
  *::before,
  *::after {
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  img {
    page-break-inside: avoid;
  }

  p,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h3 {
    page-break-after: avoid;
  }

  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }
}

figcaption,
main {
  display: block;
}

main {
  overflow: hidden;
}

.bg-white {
  background-color: #fff !important;
}

a.bg-white:hover,
a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-gradient-default {
  background: linear-gradient(87deg, #172b4d 0, #1a174d 100%) !important;
}

.bg-gradient-default {
  background: linear-gradient(87deg, #172b4d 0, #1a174d 100%) !important;
}

@keyframes floating-lg {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(15px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes floating {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes floating-sm {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(5px);
  }

  100% {
    transform: translateY(0px);
  }
}

.opacity-8 {
  opacity: 0.8 !important;
}

.opacity-8 {
  opacity: 0.9 !important;
}

.center {
  left: 50%;
  transform: translateX(-50%);
}

[class*="shadow"] {
  transition: all 0.15s ease;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.text-sm {
  font-size: 0.875rem !important;
}

.text-white {
  color: #fff !important;
}

a.text-white:hover,
a.text-white:focus {
  color: #e6e6e6 !important;
}

.avatar {
  font-size: 1rem;
  display: inline-flex;
  width: 48px;
  height: 48px;
  color: #fff;
  border-radius: 50%;
  background-color: #adb5bd;
  align-items: center;
  justify-content: center;
}

.avatar img {
  width: 100%;
  border-radius: 50%;
}

.avatar-sm {
  font-size: 0.875rem;
  width: 36px;
  height: 36px;
}

.btn {
  font-size: 0.875rem;
  position: relative;
  transition: all 0.15s ease;
  letter-spacing: 0.025em;
  text-transform: none;
  will-change: transform;
}

.btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

/* .btn:not(:last-child) {
  margin-right: 0.5rem;
} */

.btn i:not(:first-child) {
  margin-left: 0.5rem;
}

.btn i:not(:last-child) {
  margin-right: 0.5rem;
}

.input-group .btn {
  margin-right: 0;
  transform: translateY(0);
}

.btn-sm {
  font-size: 0.75rem;
}

[class*="btn-outline-"] {
  border-width: 1px;
}

/* .card-profile-image {
  position: relative;
} */

/* .card-profile-image img {
  position: absolute;
  left: 50%;
  max-width: 180px;
  transition: all 0.15s ease;
  transform: translate(-50%, -30%);
  border-radius: 0.375rem;
} */

/* .card-profile-image img:hover {
  transform: translate(-50%, -33%);
}

.card-profile-stats {
  padding: 1rem 0;
} */

.card-profile-stats>div {
  margin-right: 1rem;
  padding: 0.875rem;
  text-align: center;
}

.card-profile-stats>div:last-child {
  margin-right: 0;
}

.card-profile-stats>div .heading {
  font-size: 1.1rem;
  font-weight: bold;
  display: block;
}

.card-profile-stats>div .description {
  font-size: 0.875rem;
  color: #adb5bd;
}

.main-content {
  position: relative;
}

.main-content .navbar-top {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  padding-right: 0 !important;
  padding-left: 0 !important;
  background-color: transparent;
}

@media (min-width: 768px) {
  .main-content .container-fluid {
    padding-right: 39px !important;
    padding-left: 39px !important;
  }
}

.dropdown {
  display: inline-block;
}

.dropdown-menu {
  min-width: 12rem;
}

.dropdown-menu .dropdown-item {
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
}

.dropdown-menu .dropdown-item>i {
  font-size: 1rem;
  margin-right: 1rem;
  vertical-align: -17%;
}

.dropdown-header {
  font-size: 0.625rem;
  font-weight: 700;
  padding-right: 1rem;
  padding-left: 1rem;
  text-transform: uppercase;
  color: #f6f9fc;
}

.dropdown-menu a.media>div:first-child {
  line-height: 1;
}

.dropdown-menu a.media p {
  color: #8898aa;
}

.dropdown-menu a.media:hover .heading,
.dropdown-menu a.media:hover p {
  color: #172b4d !important;
}

.form-control-label {
  font-size: 0.875rem;
  font-weight: 600;
  color: #525f7f;
}

.form-control {
  font-size: 0.875rem;
}

.form-control:focus:-ms-input-placeholder {
  color: #adb5bd;
}

.form-control:focus::-ms-input-placeholder {
  color: #adb5bd;
}

.form-control:focus::placeholder {
  color: #adb5bd;
}

textarea[resize="none"] {
  resize: none !important;
}

textarea[resize="both"] {
  resize: both !important;
}

textarea[resize="vertical"] {
  resize: vertical !important;
}

textarea[resize="horizontal"] {
  resize: horizontal !important;
}

.form-control-alternative {
  transition: box-shadow 0.15s ease;
  border: 0;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  padding-left: 12px !important;
}

.form-control-alternative:focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.input-group {
  transition: all 0.15s ease;
  border-radius: 0.375rem;
  box-shadow: none;
}

.input-group .form-control {
  box-shadow: none;
}

.input-group .form-control:not(:first-child) {
  padding-left: 0;
  border-left: 0;
}

.input-group .form-control:not(:last-child) {
  padding-right: 0;
  border-right: 0;
}

.input-group .form-control:focus {
  box-shadow: none;
}

.input-group-text {
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.input-group-alternative {
  transition: box-shadow 0.15s ease;
  border: 0;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
}

.input-group-alternative .form-control,
.input-group-alternative .input-group-text {
  border: 0;
  box-shadow: none;
}

/* .focused .input-group-alternative {
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08) !important;
}

.focused .input-group {
  box-shadow: none;
}

.focused .input-group-text {
  color: #8898aa;
  border-color: rgba(50, 151, 211, .25);
  background-color: #fff;
}

.focused .form-control {
  border-color: rgba(50, 151, 211, .25);
}

.header {
  position: relative;
} */

.input-group {
  transition: all 0.15s ease;
  border-radius: 0.375rem;
  box-shadow: none;
}

.input-group .form-control {
  box-shadow: none;
}

.input-group .form-control:not(:first-child) {
  padding-left: 0;
  border-left: 0;
}

.input-group .form-control:not(:last-child) {
  padding-right: 0;
  border-right: 0;
}

.input-group .form-control:focus {
  box-shadow: none;
}

.input-group-text {
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.input-group-alternative {
  transition: box-shadow 0.15s ease;
  border: 0;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
}

.input-group-alternative .form-control,
.input-group-alternative .input-group-text {
  border: 0;
  box-shadow: none;
}

/* .focused .input-group-alternative {
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08) !important;
}

.focused .input-group {
  box-shadow: none;
}

.focused .input-group-text {
  color: #8898aa;
  border-color: rgba(50, 151, 211, .25);
  background-color: #fff;
}

.focused .form-control {
  border-color: rgba(50, 151, 211, .25);
} */

.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.15s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .mask {
    transition: none;
  }
}

.nav-link {
  color: #525f7f;
}

.nav-link:hover {
  color: #5e72e4;
}

.nav-link i.ni {
  position: relative;
  top: 2px;
}

.navbar-search .input-group {
  border: 2px solid;
  border-radius: 2rem;
  background-color: transparent;
}

.navbar-search .input-group .input-group-text {
  padding-left: 1rem;
  background-color: transparent;
}

.navbar-search .form-control {
  width: 270px;
  background-color: transparent;
}

.navbar-search-dark .input-group {
  border-color: rgba(255, 255, 255, 0.6);
}

.navbar-search-dark .input-group-text {
  color: rgba(255, 255, 255, 0.6);
}

.navbar-search-dark .form-control {
  color: rgba(255, 255, 255, 0.9);
}

.navbar-search-dark .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.navbar-search-dark .form-control::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.navbar-search-dark .form-control::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.navbar-search-dark .focused .input-group {
  border-color: rgba(255, 255, 255, 0.9);
}

@media (min-width: 768px) {
  .navbar .dropdown-menu {
    margin: 0;
    pointer-events: none;
    opacity: 0;
  }

  .navbar .dropdown-menu-arrow:before {
    position: absolute;
    z-index: -5;
    bottom: 100%;
    left: 20px;
    display: block;
    width: 12px;
    height: 12px;
    content: "";
    transform: rotate(-45deg) translateY(12px);
    border-radius: 2px;
    background: #fff;
    box-shadow: none;
  }

  .navbar .dropdown-menu-right:before {
    right: 20px;
    left: auto;
  }

  @keyframes show-navbar-dropdown {
    0% {
      transition: visibility 0.25s, opacity 0.25s, transform 0.25s;
      transform: translate(0, 10px) perspective(200px) rotateX(-2deg);
      opacity: 0;
    }

    100% {
      transform: translate(0, 0);
      opacity: 1;
    }
  }

  @keyframes hide-navbar-dropdown {
    from {
      opacity: 1;
    }

    to {
      transform: translate(0, 10px);
      opacity: 0;
    }
  }
}

@media (max-width: 767.98px) {
  .navbar-nav .nav-link {
    padding: 0.625rem 0;
    color: #172b4d !important;
  }

  .navbar-nav .dropdown-menu {
    min-width: auto;
    box-shadow: none;
  }
}

@keyframes show-navbar-collapse {
  0% {
    transform: scale(0.95);
    transform-origin: 100% 0;
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes hide-navbar-collapse {
  from {
    transform: scale(1);
    transform-origin: 100% 0;
    opacity: 1;
  }

  to {
    transform: scale(0.95);
    opacity: 0;
  }
}

.progress {
  overflow: hidden;
  height: 8px;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  background-color: #e9ecef;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

p {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.7;
}

.description {
  font-size: 0.875rem;
}

.heading {
  font-size: 0.95rem;
  font-weight: 600;
  letter-spacing: 0.025em;
  text-transform: uppercase;
}

.rtl .heading-small {
  font-size: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  border-bottom: 1px solid #e0be4f78;
  font-family: "Cairo", sans-serif !important;
}

.ltr .heading-small {
  font-size: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  border-bottom: 1px solid #e0be4f78;

}

.display-2 span {
  font-weight: 300;
  display: block;
}

@media (max-width: 768px) {
  .btn {
    margin-bottom: 10px;
  }
}

#navbar .navbar {
  margin-bottom: 20px;
}

select.form-control {
  padding: 0.4375rem 0.75rem;
  border: 0;
  outline: 1px solid #ebedf2;
  color: #c9c8c8;
  height: calc(2.25rem + 13px);
}

.form-group .input_wrap ul {
  box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
  width: 100%;
  background: transparent;
  margin: 0 auto;
  padding: 11px 14px;
  border-radius: 0px;
  display: flex;
  justify-content: center;
  border: none;
}

.form-group .input_wrap ul .radio_wrap span {
  display: inline-block;
  font-size: 17px;
  padding: 7px 15px;
  border-radius: 0px;
  color: #101749;
}

.form-group .input_wrap .input_radio:checked~span {
  background: #012690;
  color: white;
}

.ltr .customAlignmentLtr {
  margin: 0px 0px 0px auto;
  padding: inherit;
}

.rtl .customAlignmentLtr {
  margin: 0px auto 0px 0px;
  padding: inherit;
}

.rtl h4 {
  font-family: "Cairo", sans-serif !important;
}

.rtl h3 span {
  margin-left: 6px !important;
}



.main-head {
  background: #012690;
  box-shadow: 0px 1px 10px -6px rgba(0, 0, 0, 0.15);
  padding: 1rem;
  margin-bottom: 0;
  margin-top: 5rem;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 4px;
  font-size: 16px;
}

.pricing-table {
  background: #fff;
  /* box-shadow: 0px 1px 10px -6px rgba(0, 0, 0, 0.15); */

  border-radius: 4px;
  transition: 0.3s;
}

.pricing-table:hover {
  /* box-shadow: 0px 1px 10px -4px rgba(0, 0, 0, 0.15); */
}

.pricing-table .pricing-label {
  border-radius: 2px;
  padding: 0.25rem 0.5rem;
  margin-bottom: 1rem;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
}

.pricing-table h2 {
  color: #3b3b3b;
  font-size: 24px;
  font-weight: 500;
}

.pricing-table h5 {
  color: #b3b3b3;
  font-size: 14px;
  font-weight: 400;
}

.pricing-table .pricing-features {
  margin-top: 2rem;
}

.pricing-table .pricing-features .feature {
  font-size: 14px;
  margin: 1.5rem 0;
  color: #b3b3b3;
}

.pricing-table .pricing-features .feature span {
  display: inline-block;
  float: right;
  color: #3b3b3b;
  font-weight: 500;
}

.pricing-table .price-tag {
  margin-top: 2rem;
  text-align: center;
  font-weight: 500;
  padding-bottom: 10px;
}

.pricing-table .price-tag .symbol {
  font-size: 15px;
}

.pricing-table .price-tag .amount {
  letter-spacing: -2px;
  font-size: 30px;
}

.pricing-table .price-tag .after {
  color: #3b3b3b;
  font-weight: 500;
}

.pricing-table .price-button {
  display: block;
  color: #fff;
  margin-top: 2rem;
  padding: 0.75rem;
  border-radius: 2px;
  text-align: center;
  font-weight: 500;
  transition: 0.3s;
}

.pricing-table .price-button:hover {
  text-decoration: none;
}

.purple .pricing-label {
  background: #cad2ff;
  color: #627afe;
}

.purple .price-tag {
  color: #627afe;
}

.purple .price-button {
  background: #627afe;
}

.purple .price-button:hover {
  background: #546dfe;
}

.turquoise .pricing-label {
  background: #b9edee;
  color: #44cdd2;
}

.turquoise .price-tag {
  color: #44cdd2;
}

.turquoise .price-button {
  background: #44cdd2;
}

.turquoise .price-button:hover {
  background: #2dbcc4;
}

.red .pricing-label {
  background: #ffc4c4;
  color: #ff5e5e;
}

.red .price-tag {
  color: #012690;
}

.red .price-button {
  background: #ff5e5e;
}

.red .price-button:hover {
  background: #f23c3c;
}

.phoneChange {
  margin: 0 auto;
  background-color: #d9b327;
  width: 63px;
  height: 63px;
  padding-bottom: 0;
  margin-bottom: -28px;
  padding-top: 15px;
  border-radius: 50%;
}

.positionPhone {
  z-index: 9;
  position: inherit;
}

.input1001 {
  font-family: Raleway-SemiBold;
  font-size: 15px;
  line-height: 1.2;
  color: #686868a6;
  display: block;
  width: 100%;
  border-color: #c0b3b3;
  background: #e6e6e6;
  height: 47px;
  border-radius: 3px;
  padding: 0 30px 0 12px;
}


.ft-recipe {

  background: var(--white);
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 88px 0 rgba(0, 0, 0, 0.1607843137);
  overflow: hidden;
  top: 50%;
  right: 50%;
  bottom: 50%;
  left: 50%;
  position: relative;
  transform: translate(-50%, -50%);
}

.ft-recipe .ft-recipe__thumb {
  position: relative;
  /* height: 281px; */
  box-shadow: 0px 0px 33px 0 rgba(0, 0, 0, 0.38);
}

.ft-recipe .ft-recipe__thumb #close-modal {
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
  height: 36px;
  background: #000;
  color: var(--white);
  text-align: center;
  line-height: 36px;
  font-size: 22px;
  cursor: pointer;
  z-index: 1;
  transition: all 200ms ease;
}

.ft-recipe .ft-recipe__thumb #close-modal:hover {
  background: transparent;
  color: var(--black);
}

.ft-recipe .ft-recipe__thumb h3 {
  text-align: left;
  position: absolute;
  margin: 0;
  width: 100%;
  color: var(--white);
  font-family: var(--headlinesFont);
  font-size: 20px;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.25), #0000003d);
  padding: 10.4rem 10px 0;
}

.ft-recipe .ft-recipe__thumb img {
  width: 100%;
  height: 300px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

.ft-recipe .ft-recipe__content {
  flex: 1;
  padding: 0 2em 1em;
}

.ft-recipe .ft-recipe__content .content__header .row-wrapper {
  display: flex;
  padding: 0.55em 0 0.3em;
  border-bottom: 1px solid #d8d8d8;
}

/* .ft-recipe .ft-recipe__content .content__header .row-wrapper .recipe-title {
  font-family: var(--headlinesFont);
  font-weight: 600;
} */
.ft-recipe .ft-recipe__content .content__header .recipe-details {
  display: flex;
  list-style: none;
  padding: 1.4em 0 1em;
  margin: 0;
  justify-content: space-between;
}

.ft-recipe .ft-recipe__content .content__header .recipe-details .recipe-details-item {
  flex: 1;
}

.ft-recipe .ft-recipe__content .content__header .recipe-details .recipe-details-item i {
  font-size: 30px;
}

.ft-recipe .ft-recipe__content .content__header .recipe-details .recipe-details-item .value {
  color: #ff4f87;
  margin-left: 0.35em;
  vertical-align: bottom;
  font-size: 24px;
  font-weight: 600;
}

.ft-recipe .ft-recipe__content .content__header .recipe-details .recipe-details-item .title {
  display: block;
  margin-top: -4px;
  font-family: var(--headlinesFont);
  font-size: 21px;
  font-weight: 300;
}

.ft-recipe .ft-recipe__content .description {
  margin: 0.2em 0 0.0em;
}

.ft-recipe .ft-recipe__content .content__footer {
  text-align: center;
  margin: 0 3rem;
}

.ft-recipe .ft-recipe__content .content__footer a {

  display: inline-block;
  background: #012690;
  padding: 0.45em 1em;
  width: 100%;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  letter-spacing: 0.2px;
  font-size: 17px;
  transition: transform 250ms ease, box-shadow 250ms ease;
}

.ft-recipe .ft-recipe__content .content__footer a:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 34px 0 rgba(255, 79, 135, 0.32);
}



.text-sm {
  font-size: .875rem;
}

.text-gray-700 {
  color: #4a5568;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.font-semibold {
  font-weight: 600;
}

.inline-block {
  display: inline-block;
}

.rounded-full {
  border-radius: 9999px;
}

.bg-gray-200 {
  background-color: #edf2f7;
}

.groupBtn {
  padding: 17px;
  width: 100%;
  margin-top: 15px;
}

.myEvents {
  margin-bottom: 0px !important;
  font-size: 14px;
  line-height: 1.4;
}

.btn-custom:hover {
  transform: none !important;
  box-shadow: none !important;
  text-decoration: underline !important;
}

.btn-custom:focus {
  outline: 0;
  box-shadow: none !important;
}

.calendar {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(7, minmax(76px, 1fr));
  grid-template-rows: 43px;
  grid-auto-rows: 51px;


}

.calendar-container {
  width: 100%;
  margin: auto;
  overflow: inherit;
  border-radius: 10px;
  background: #fff;
  max-width: 1200px;
}

.calendar-header {
  text-align: center;
  padding: 0px 0;
  padding-top: 7px;
  background: linear-gradient(to bottom, #fafbfd 0%, rgba(255, 255, 255, 0) 100%);
  border-bottom: 1px solid rgba(166, 168, 179, 0.12);
}

.calendar-header h1 {
  margin: 0;
  font-size: 18px;
}

.calendar-header p {
  margin: 5px 0 0 0;
  font-size: 13px;
  font-weight: 600;
  color: rgba(81, 86, 93, 0.4);
}

.calendar-header button {
  background: 0;
  border: 0;
  padding: 0;
  color: rgba(81, 86, 93, 0.7);
  cursor: pointer;
  outline: 0;
}

.day {
  border-bottom: 1px solid rgba(166, 168, 179, 0.12);
  /* border-right: 1px solid rgba(166, 168, 179, 0.12); */
  text-align: right;
  padding: 10px 7px;
  letter-spacing: 1px;
  font-size: 19px;
  box-sizing: border-box;
  color: #98a0a6;
  position: relative;
  pointer-events: none;
  z-index: 1;
  text-align: center;
}

.day:nth-of-type(7n + 7) {
  border-right: 0;
}

.day:nth-of-type(n + 1):nth-of-type(-n + 7) {
  grid-row: 2;
}

.day:nth-of-type(n + 8):nth-of-type(-n + 14) {
  grid-row: 3;
}

.day:nth-of-type(n + 15):nth-of-type(-n + 21) {
  grid-row: 4;
}

.day:nth-of-type(n + 22):nth-of-type(-n + 28) {
  grid-row: 5;
}

.day:nth-of-type(n + 29):nth-of-type(-n + 35) {
  grid-row: 6;
}

.day:nth-of-type(7n + 1) {
  grid-column: 1/1;
}

.day:nth-of-type(7n + 2) {
  grid-column: 2/2;
}

.day:nth-of-type(7n + 3) {
  grid-column: 3/3;
}

.day:nth-of-type(7n + 4) {
  grid-column: 4/4;
}

.day:nth-of-type(7n + 5) {
  grid-column: 5/5;
}

.day:nth-of-type(7n + 6) {
  grid-column: 6/6;
}

.day:nth-of-type(7n + 7) {
  grid-column: 7/7;
}

.day-name {
  font-size: 12px;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  border-bottom: 1px solid rgba(166, 168, 179, 0.12);
  line-height: 50px;
  font-weight: 500;
  background-color: #001348;
}

.day--disabled {
  color: rgba(152, 160, 166, 0.6);
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23f9f9fa' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
  cursor: not-allowed;
  height: 48px;
  padding-top: 10px;
}

.task {
  border-left-width: 3px;
  padding: 8px 12px;
  margin: 10px;
  border-left-style: solid;
  font-size: 14px;
  position: relative;
}

.task--warning {
  border-left-color: #fdb44d;
  grid-column: 4/span 3;
  grid-row: 3;
  background: #fef0db;
  align-self: center;
  color: #fc9b10;
  margin-top: -5px;
}

.task--danger {
  border-left-color: #fa607e;
  grid-column: 2/span 3;
  grid-row: 3;
  margin-top: 15px;
  background: rgba(253, 197, 208, 0.7);
  align-self: end;
  color: #f8254e;
}

.task--info {
  border-left-color: #4786ff;
  grid-column: 6/span 2;
  grid-row: 5;
  margin-top: 15px;
  background: rgba(218, 231, 255, 0.7);
  align-self: end;
  color: #0a5eff;
}

.task--primary {
  background: #4786ff;
  border: 0;
  border-radius: 4px;
  grid-column: 3/span 3;
  grid-row: 4;
  align-self: end;
  color: #fff;
  box-shadow: 0 10px 14px rgba(71, 134, 255, 0.4);
}

.task__detail {
  position: absolute;
  left: 0;
  top: calc(100% + 10px);
  background: #fff;
  border: 1px solid rgba(166, 168, 179, 0.2);
  color: #000;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.08);
  z-index: 2;
}

.task__detail:after,
.task__detail:before {
  bottom: 100%;
  left: 30%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.task__detail:before {
  border-bottom-color: rgba(166, 168, 179, 0.2);
  border-width: 8px;
  margin-left: -8px;
}

.task__detail:after {
  border-bottom-color: #fff;
  border-width: 6px;
  margin-left: -6px;
}

.task__detail h2 {
  font-size: 15px;
  margin: 0;
  color: #51565d;
}

.task__detail p {
  margin-top: 4px;
  font-size: 12px;
  margin-bottom: 0;
  font-weight: 500;
  color: rgba(81, 86, 93, 0.7);
}

.button1 {
  padding: 5px;
  margin-bottom: 0px;
  width: 45%;
  cursor: pointer;
}

.active-session {
  background-color: #001348;
  color: #fff;
}

.selected-date {
  border: 1px solid #001348;
}

.rtl .calendar {
  grid-auto-rows: 54px !important;
}

.availabel_Seesions {
  height: 10px;
  width: 10px;
  background-color: #001348;
  border-radius: 50%;
  display: inline-block;
}

.active-session .availabel_Seesions {
  height: 10px;
  width: 10px;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
}

.active-session p {
  color: #fff;
}

.borderRightCheckbox {
  border-right: 1px solid #001348
}

.active-session .borderRightCheckbox {
  border-right: 1px solid #fff
}

.rowCustomepaddingMargin {
  /* margin-top: 10px;
    margin-bottom: 10px; */
  padding-top: 9px;
  padding-bottom: 9px;
}

.chckboxstyle {
  padding-top: 0px;
  padding-bottom: 0px;
  cursor: pointer;
  border: 1px solid #ccc;
  margin-bottom: 5px;
}

.link {
  display: block;
  color: rgba(0, 0, 0, 0.54);
  margin-top: 54px;
}

.checkbox-input {
  display: none;
}

.checkbox-label,
.checkbox-text,
.checkbox-text--description {
  transition: all 0.4s ease;
}

.checkbox-label {
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 100%;
  padding: 0px 1px;
  cursor: pointer;
  font-weight: 400;
  border-bottom: 1px solid #f4f4f6;
  border-radius: 2px;
  box-shadow: inset 0 0 0 0 #2196F3;
}

.checkbox-label:before {
  /* content: "";
  position: absolute;
  top: 75%;
  right: 16px;
  width: 40px;
  height: 40px;
  opacity: 0;
  background-color: #2196F3;
  background-image: url(http://lorenzodianni.io/codepen/icon/done--white.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
  border-radius: 50%;
  transform: translate(0%, -50%);
  transition: all 0.4s ease; */
}

.checkbox-text--title {
  font-weight: 500;
}

.checkbox-text--description {
  font-size: 14px;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #2196F3;
}

.checkbox-text--description .un {
  display: none;
}

.checkbox-input:checked+.checkbox-label {

  /* box-shadow: 3px 3px 3px #001348; */
  background-color: #001348;
}

.checkbox-input:checked+.checkbox-label p {
  color: #fff;
}

.checkbox-input:checked+.checkbox-label:before {
  top: 0;
  opacity: 1;
}

.checkbox-input:checked+.checkbox-label .checkbox-text {
  /* transform: translate(0, -8px); */
}

.checkbox-input:checked+.checkbox-label .checkbox-text--description {
  border-color: #d9d9d9;
}

.checkbox-input:checked+.checkbox-label .checkbox-text--description .un {
  display: inline-block;
}

@media screen and (min-width: 540px) {
  .checkbox-label {}

}

.decoration {
  border-bottom: 1px solid #e7e6e6;
}

.error {
  color: red;
  border-left: 4px solid;
  padding-left: 6px;
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999 !important;
  background: rgba(255, 255, 255, 0.9);
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.menu--disabled {
  background-color: #ffffff;
  cursor: not-allowed !important
}

.rtl .alignment {
  text-align: left;
}

.ltr .alignment {
  text-align: right;
}

.scroll-inner::-webkit-scrollbar {
  width: 5px;
}

.scroll-inner::-webkit-scrollbar:horizontal {
  height: 10px;
}

.width25persent {
  width: 25%;
}

.top-text-wrapper {
  margin: 20px 0 30px 0;
}

.top-text-wrapper h4 {
  font-size: 24px;
  margin-bottom: 10px;
}

.top-text-wrapper code {
  font-size: 0.85em;
  background: linear-gradient(90deg, #fce3ec, #ffe8cc);
  color: #ff2200;
  padding: 0.1rem 0.3rem 0.2rem;
  border-radius: 0.2rem;
}

.tab-section-wrapper {
  padding: 30px 0;
}



.grid-col-auto {
  grid-auto-flow: column;
  grid-template-rows: auto;
}

/* ******************* Main Styeles : Radio Card */
label.radio-card {
  cursor: pointer;
  min-width: 230px;
}

label.radio-card .card-content-wrapper {
  background: #fff;
  border-radius: 5px;
  max-width: 280px;
  min-height: 260px;
  padding: 10px;
  display: grid;
  box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.04);
  transition: 200ms linear;
}

label.radio-card .check-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  border: solid 2px #e3e3e3;
  border-radius: 50%;
  transition: 200ms linear;
  position: relative;
}

label.radio-card .check-icon:before {
  content: "";
  position: absolute;
  inset: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: center center;
  transform: scale(1.6);
  transition: 200ms linear;
  opacity: 0;
}

label.radio-card input[type=radio] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

label.radio-card input[type=radio]:checked+.card-content-wrapper {
  box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.5), 0 0 0 2px #3057d5;
}

label.radio-card input[type=radio]:checked+.card-content-wrapper .check-icon {
  background: #3057d5;
  border-color: #3057d5;
  transform: scale(1.2);
}

label.radio-card input[type=radio]:checked+.card-content-wrapper .check-icon:before {
  transform: scale(1);
  opacity: 1;
}

label.radio-card input[type=radio]:focus+.card-content-wrapper .check-icon {
  box-shadow: 0 0 0 4px rgba(48, 86, 213, 0.2);
  border-color: #3056d5;
}

label.radio-card .card-content img {
  margin-bottom: 10px;
}

label.radio-card .card-content h4 {
  font-size: 16px;
  letter-spacing: 0.9px;
  text-align: center;
  color: #1f2949;
  margin-bottom: 10px;
  font-family: 'Balsamiq Sans', cursive !important;
}

label.radio-card .card-content h5 {
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
  color: #686d73;
}



@media (min-width:320px) and (max-width: 480px) {
  .active-session {
    background-color: #001348;
    color: #fff;
    line-height: 1.8;
  }

  .calendar {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(7, minmax(36px, 1fr));
    grid-template-rows: 53px;
    grid-auto-rows: 60px;
  }

  /* smartphones, iPhone, portrait 480x320 phones */
  .plans .plan .plan-content {
    padding: 20px;
    flex-direction: row;
    align-items: flex-start;
  }

  .plans {
    margin: 0px 0;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    padding-top: 20px;
  }

  .button {
    background: rgb(0 19 72);
    border-radius: 5px;
    padding: 9px 17px;
    display: inline-block;
    margin: 10px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgb(0 0 0 / 50%);
    font-size: 12px;
  }

  .buttonPre {
    background: #e0be4f;
    border-radius: 5px;
    padding: 9px 13px;
    display: inline-block;
    margin: 10px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgb(0 0 0 / 50%);
    font-size: 12px;
  }

  .grid-col-auto {
    grid-auto-flow: unset;
  }

  .desktop-swithchbtn {
    display: none;
  }

  .navbar .navbar-menu-wrapper {
    width: auto;
    background-color: #ffff !important;
  }

  .desktop-scannicon {
    display: none;
  }

  .service-item {
    width: 116px;
  }

  .width25persent {
    width: 80%;
  }

  .ltr .job-card__content .job-card_img {
    margin-right: 0;
    padding-bottom: 10px;
    position: relative;

  }

  .rtl .job-card__content .job-card_img {
    margin-left: 0;
    padding-bottom: 10px;
    position: relative;

  }

  .rtl nav ul {
    padding-right: 0px !important;
  }
}

@media (min-width:481px) and (max-width: 640px) {

  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
  .desktop-swithchbtn {
    display: none;

  }

  .desktop-scannicon {
    display: none;
  }

  .navbar .navbar-menu-wrapper {
    width: auto;
    background-color: #ffff !important;
  }

  .service-item {
    width: 116px;
  }

  .width25persent {
    width: 100%;
  }

  .ltr .job-card__content .job-card_img {
    margin-right: 0;
    padding-bottom: 10px;
  }

  .rtl .job-card__content .job-card_img {
    margin-left: 0;
    padding-bottom: 10px;
  }

  .rtl nav ul {
    padding-right: 0px !important;
  }
}

@media only screen and (min-device-width: 768px) {
  .ltr nav .sideFixed {
    height: 100%;
    position: fixed;
    z-index: 9999;
    left: inherit;
    padding-top: 20px;
    width: 260px;
  }

  .rtl nav .sideFixed {
    height: 100%;
    position: fixed;
    z-index: 9999;
    right: inherit;
    padding-top: 20px;
    width: 260px;
  }

  .width25persent {
    width: 15%;
  }

  .ltr .job-card__content .job-card_info {
    flex: auto;
    padding-right: 0;
    padding-left: 8px;
  }
}

@media (min-width:641px) and (max-width: 960px) {

  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  label.radio-card {
    cursor: pointer;
    min-width: 202px;
  }

  .desktop-swithchbtn {
    display: none;
    background-color: #ffff !important;
  }

  .navbar .navbar-menu-wrapper {
    width: auto;
    background-color: #ffff !important;
  }

  .desktop-scannicon {
    display: none;
  }

  .service-item {
    width: 116px;
  }

  .width25persent {}

  .ltr .job-card__content .job-card_img {
    margin-right: 0;
    padding-bottom: 10px;
  }

  .rtl .job-card__content .job-card_img {
    margin-left: 0;
    padding-bottom: 10px;
  }

  .rtl nav ul {
    padding-right: 0px !important;
  }
}

@media (min-width:801px) {
  .sideprofile {
    padding-top: 80px !important;
  }

  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .ltr nav .sideFixed {
    height: 100%;
    position: fixed;
    z-index: 9999;
    left: inherit;
    padding-top: 20px;
    width: 260px;
  }

  .rtl nav .sideFixed {
    height: 100%;
    position: fixed;
    z-index: 9999;
    left: inherit;
    padding-top: 20px;
    width: 260px;
  }

  .profile-card {
    width: 30%;
  }
}

@media (min-width:961px) and (max-width: 1024px) {
  .sideprofile {
    padding-top: 80px !important;
  }

  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .profile-card {
    width: 22%;
  }

  label.radio-card {
    cursor: pointer;
    min-width: 230px;
    margin: 10px;
  }

  .grid-wrapper {
    display: block;
    grid-gap: 10px;
    place-items: center;
    place-content: center;
  }

  .profile-card {
    width: 32%;
  }

  label.radio-card {
    cursor: pointer;
    min-width: 202px;
  }

  .mobile-scannicon {
    display: none;
  }

  .mobile-swithchbtn {
    display: none;
  }
}

@media (min-width:1025px) and (max-width: 1280px) {

  /* big landscape tablets, laptops, and desktops */
  .sideprofile {
    padding-top: 80px !important;
  }

  .profile-card {
    width: 22%;
  }

  .mobile-scannicon {
    display: none;
  }

  .mobile-swithchbtn {
    display: none;
  }
}

@media (min-width:1281px) {
  .profile-card {
    width: 22%;
  }

  .mobile-scannicon {
    display: none;
  }

  .mobile-swithchbtn {
    display: none;
  }
}













.fileCustome {
  width: 50%;
  height: 9em;
  position: absolute;
  margin: 0 auto;
  right: 0;
  left: 0;
  top: 6px;
  cursor: pointer;
}

/* .custom-file-input:hover {
  opacity: 0.5;
}

.fileCustome+img {
  cursor: pointer;
} */

.card-profile {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.card-profile:hover {
  transform: scale(1.02);
  /* Subtle hover effect */
}

.card-profile-image img {
  max-width: 150px;
  border-radius: 50%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.profile-details .user-name {
  font-size: 22px;
  font-weight: 600;
  margin-top: 15px;
  color: #333;
}

.profile-details .user-info {
  font-size: 16px;
  color: #666;
  margin-top: 5px;
}

.pricing-table {
  background-color: #f5f7fa;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.pricing-features .feature {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  margin-bottom: 10px;
}

.price-tag {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
}

.expired {
  color: red;
  font-weight: bold;
}

.days-left {
  font-size: 26px;
  color: #4CAF50;
  font-weight: bold;
}

.days-text {
  color: #777;
  font-size: 18px;
}

.btn-gradient-primary {
  background: linear-gradient(135deg, #6a11cb, #2575fc);
  color: #fff;
  border-radius: 50px;
  padding: 10px 20px;
}

.btn-outline-primary {

  padding: 0px;
}

.btn-link {
  font-size: 14px;
  text-align: left;
}

/* Container for Buttons */
.button-group {
  display: flex;
  flex-wrap: wrap;
  /* Makes it responsive */
  justify-content: space-between;
  /* Aligns buttons evenly */
  gap: 10px;
  /* Adds space between buttons */
}

/* Button Styling */
.btn {
  flex-grow: 0;
  /* Ensures buttons take up equal space */
  /* Minimum width to prevent shrinking on small screens */
  padding: 10px 20px;
  /* Consistent padding */
  height: 50px;
  /* Consistent height */
  align-items: center;
  justify-content: center;
  /* Centers content inside the button */
}

/* Buttons on small devices */
@media (max-width: 576px) {
  .button-group {
    flex-direction: column;
    /* Stacks buttons vertically on smaller screens */
  }

  button {

    /* Full width on small devices */
    margin-bottom: 10px;
    /* Adds spacing between buttons */
  }
}

/* Button Gradient for Primary Button */
.renewal-btn {
  background: linear-gradient(45deg, #f12711, #f5af19);
  border: none;
  color: white;
  font-weight: bold;
  transition: background 0.3s ease;
}

.renewal-btn:hover {
  background: linear-gradient(45deg, #f12711, #f12720);
}

/* Freeze Button */
.freeze-btn {

  font-weight: bold;
  transition: all 0.3s ease;
}

.freeze-btn:hover {
  color: white;
}

/* History Button */
.history-btn {
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
  padding: 10px;
}

.history-btn:hover {
  color: #f12711;
}

/* Ribbon Wrapper */
.ribbon-wrapper {
  position: relative;
  margin-bottom: 15px;
}

.ribbon {
  position: absolute;
  top: -40px;
  right: -10px;
  background: #ff5f5f;
  color: white;
  padding: 5px 15px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  /* transform: rotate(45deg); */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

/* Days Left Ribbon Styling */
.days-left-ribbon .ribbon {
  background: #00c853;
  /* Green background for remaining days */
}

/* Expired Ribbon Styling */
.expired-ribbon .ribbon {
  background: #ff5252;
  /* Red background for expired */
}


/*Sports*/