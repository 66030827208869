/* Flexbox settings for the grid-wrapper to handle responsiveness */
.grid-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
    /* Add space between items */
}

/* Default for mobile view - Two items per row */
.new-grid-item {
    flex: 1 1 calc(50% - 10px);
    /* Two items per row */
    margin: 10px;
    max-width: calc(50% - 10px);
    height: 250px;
    /* Set a default height */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

/* Style for image inside the card */
.new-grid-item img {
    max-width: 110px;
    margin-bottom: 10px;
    height: auto;
    /* Ensure image scales proportionally */
}

/* Card content styles */
.new-card-content {
    padding: 15px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

/* Hover effect on cards */
.new-card-content:hover {
    transform: translateY(-5px);
}

/* Header text styling */
.new-grid-item h4 {
    font-size: 16px;
    margin-top: 10px;
    text-align: center;
}

/* Mobile view - Two items per row */
@media (max-width: 767px) {
    .new-grid-item {
        flex: 1 1 calc(50% - 10px);
        /* Ensure two items per row */
        max-width: calc(44% - 10px);
        height: 160px;
        /* Set default height */
    }

    .new-grid-item img {
        max-width: 80px;
    }

    .new-card-content {
        padding: 10px;
        background-color: #fff;
    }
}

/* Tablet view - Three items per row */
@media (min-width: 768px) and (max-width: 1023px) {
    .new-grid-item {
        flex: 1 1 calc(33.33% - 20px);
        /* Three items per row */
        max-width: calc(33.33% - 20px);
        height: 300px;
        /* Adjust height */
    }

    .new-card-content {
        padding: 15px;
    }
}

/* Desktop view - Four items per row */
@media (min-width: 1024px) {
    .new-grid-item {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: calc(25% - 0px);
        max-width: calc(17% - 0px);
        height: 214px;
    }

    .new-card-content {
        padding: 20px;
    }
}