.empty-state {
    background: #ffffff;
    border-radius: 4px;

    &__content {
        display: flex;
        align-items: center;
        flex-direction: column;

        .empty-state__icon {
            width: 200px;
            height: 200px;
            display: flex;
            align-items: center;
            border-radius: 200px;
            justify-content: center;

            img {
                width: 170px;
            }
        }

        .empty-state__message {
            color: indianred;
            font-size: 1.5rem;
            font-weight: 500;
        }

        .empty-state__help {
            color: #a2a5b9;
            font-size: 0.875rem;
        }
    }
}

.credit {
    color: #a2a5b9;
    font-size: 0.75rem;
    text-align: center;

    a {
        color: #444;
    }
}

//new css
$color_ysh_red: #000;
$color_ysh_org: #012690;
$color_ysh_grn: #5dac48;
$color_ysh_gry: #d0ddcb;
$color_bse_gry: #999;

.product-single {
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    min-width: 40%;
    font-size: 16px;
    z-index: 1;

    .product-gallery {
        margin-bottom: -4px;
        overflow: hidden;
        float: left;
        width: 40%;
        height: 300px;
        border-right: 1px solid $color_ysh_gry;
        position: relative;

        &:hover {
            .slider-arrows {
                opacity: 1;
                height: 30px;
            }
        }

        ul,
        li {
            margin: 0;
            padding: 0;
            list-style: none;
        }

        .slider-arrows {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            margin: auto;
            height: 0;
            font-size: 30px;
            color: #fff;
            padding: 0 10px;
            opacity: 0;
            transition: all .2s ease-in;
            z-index: 1;

            i {
                text-shadow: 0 0 7px rgba(0, 0, 0, 0.4);
                cursor: pointer;
                color: rgba(255, 255, 255, .8);
                transition: all .2s ease-in-out;

                &:hover {
                    color: #fff;
                }

                &:first-child {
                    float: left;
                }

                &:last-child {
                    float: right;
                }
            }
        }

        #slider-wrap {
            position: relative;
            background: transparent;
            overflow: hidden;
            height: 380px;
            width: 100%;

            .slides {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                transition: all .2s .1s ease-out;

                li {
                    float: left;
                    width: 100%;
                    height: 100%;
                    position: relative;
                }

                img {
                    max-width: 100%;
                    height: 300px;
                    object-fit: cover;
                    object-position: 50% 50%;
                }
            }
        }

        .nav-dots {
            display: block;
            margin: auto;
            text-align: center;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 12px;

            li {
                height: 8px;
                width: 8px;
                border-radius: 50%;
                margin-right: 10px;
                position: relative;
                display: inline-block;
                background: transparent;

                &:before {
                    content: "";
                    position: absolute;
                    background: transparent;
                    top: -50%;
                    bottom: -50%;
                    right: -50%;
                    left: -50%;
                    border-radius: 50%;
                    border: 3px solid #fff;
                    box-shadow: 0 0 7px -2px #000;
                }

                &.active {
                    background: $color_ysh_org;
                    transition: all .1s .1s linear;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .product-details {
        padding: 20px;
        width: 60%;
        height: auto;
        float: left;
        box-sizing: border-box;
        font-size: 14px;
        position: relative;

        .product-title {
            margin-top: 0;
            line-height: 1;
            color: #000;
        }

        .product-cost {
            font-size: 20px;
            margin-bottom: 10px;
            color: $color_ysh_grn;
        }

        .product-rating {
            .product-reviews {
                font-family: "Lato";
                margin-left: 15px;
                font-size: 12px;

                a {
                    color: $color_bse_gry;
                    text-transform: uppercase;
                    text-decoration: none;
                    transition: color .05s ease;

                    &:hover {
                        text-decoration: underline;
                        color: $color_ysh_org
                    }
                }
            }

            ul {
                display: inline-block;
            }

            ul,
            li {
                padding: 0;
                margin: 0;
                list-style: none;
            }

            li {
                color: #fcd000;
            }
        }

        .product-description {
            font-size: 15px;
            line-height: 1.1;
            overflow: hidden;
            max-height: 150px;
            margin: 10px 0;
        }

        .product-cta {
            text-align: center;
            margin: 13px 0;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 40px;
        }

        .product-atc,
        .product-atw {
            font-size: 16px;
            padding: 10px 22px;
            color: #fff;
            border: none;
            margin: auto;
            border-radius: 20px;
            transition: all .1s ease;
            margin-right: 10px;

            &:last-child {
                margin: 0;
            }

            &:focus {
                outline: none;
            }

            &:hover {
                background: $color_ysh_org;
            }
        }

        .product-atc {
            background: $color_ysh_grn;
        }

        .product-atw {
            color: $color_bse_gry;

            &:hover {
                color: #fff;
            }
        }

        .product-info {
            font-size: 11px;
            font-family: "Lato";
            position: absolute;
            bottom: 20px;
            margin-top: 10px;
            color: $color_bse_gry;

            span {
                display: inline-block;
                margin-right: 10px;
            }
        }
    }
}

ul .previous:hover {
    background: transparent;
}

@media (min-width:320px) and (max-width: 480px) {
    .product-single .product-details {
        width: 100%;
        height: 100%;
    }

    .wrapper {
        height: auto;
        min-height: 100vh;
        padding-top: 0px;
    }

    .product-single .product-gallery {
        width: 100%;
        height: auto;
    }

    .product-single .product-gallery #slider-wrap .slides {
        width: 100%;
        height: auto;
        position: relative;
    }

    .product-single .product-gallery #slider-wrap {
        position: relative;
        background: transparent;
        overflow: hidden;
        height: 100%;
        width: 100%;
    }

}