/* Profile Card Styles */
.profile-card {
    flex: 1 1 calc(50% - 20px);
    /* Two items per row on mobile */
    margin: 10px;
    max-width: calc(50% - 20px);
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.profile-card-ctr {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    text-align: center;
    padding-bottom: 0px;
    /* border-top: 1px solid #01269036; */
    padding-top: 0px;
    padding-bottom: 0px;
}

.ltr .basic {
    width: 120px;
    background: #012690;
    color: white;
    padding: 5px;
    border-top-right-radius: 13px;
    border-bottom-right-radius: 13px;
    font-size: 16px;
    text-align: center;
    position: absolute;
    /* Position it relative to its container */
    left: 0;
    /* Align to the left */
    top: 6px;
    /* Adjust the top position */
    z-index: 1;
    /* Ensure it's on top of other elements if needed */
}

.rtl .basic {
    width: 120px;
    background: #012690;
    color: white;
    padding: 0px;

    font-size: 16px;
    text-align: center;
    position: absolute;
    /* Position it relative to its container */
    right: 0;
    /* Align to the left */
    top: 6px;
    /* Adjust the top position */
    z-index: 1;
    /* Ensure it's on top of other elements if needed */
}

.btn {
    flex-grow: 0;
    padding: 6px 20px;
    height: auto;
    align-items: center;
    justify-content: center;
}

.profile-card__cnt {

    text-align: center;
    padding: 0 0px;
    padding-bottom: 0px;
    transition: all 0.3s;
    min-height: auto;
}

/* Image section */
.service-icon {
    max-width: 80px;
    height: auto;
    margin-bottom: 10px;
}

/* Responsive media queries */
@media screen and (max-width: 576px) {
    .profile-card__img {
        width: 120px;
        height: auto;
    }
}

/* For tablets and medium devices - 3 items per row */
@media (min-width: 768px) and (max-width: 1023px) {
    .profile-card {
        flex: 1 1 calc(33.33% - 20px);
        max-width: calc(33.33% - 20px);
    }

}

/* For desktop - 4 items per row */
@media (min-width: 1024px) {
    .profile-card {
        flex: 1 1 calc(25% - 20px);
        max-width: calc(25% - 20px);
    }
}

/* Ensure container wraps the content properly */
.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
}